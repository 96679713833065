<template>
    <v-container fluid>
        <v-row v-if="can(['manager', 'receiver'])">
            <v-col class="text-center mt-10" sm="12" cols="12">
                {{$t('please_select_type_of_transport_when_arriving_at_terminal')}}
            </v-col>
            <v-col class="text-center" sm="12" cols="12">
                <v-card slot="footer" key="footer" class="d-inline-block mx-10 my-5 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"

                        v-for="(entrance, i) in entrances"
                        :key="i"
                >
                    <v-container class="fill-height py-10 justify-center"  @click="getEntrance(entrance)">
                        <v-icon > {{ entrance.slug === 'entrance_auto' ? 'mdi-truck-plus-outline' :'mdi-train'}}  mdi-48px</v-icon>
                        <v-card-subtitle> {{ entrance.name }}</v-card-subtitle>
                    </v-container>
                </v-card>
            </v-col>

            <v-col class="text-center" sm="12" cols="12">
                <v-card slot="footer" key="footer" class="d-inline-block mx-10 my-5 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"

                        v-for="(departure, i) in departures"
                        :key="i"
                >
                    <v-container class="fill-height py-10 justify-center"  @click="getDepartures(departure)">
                        <v-icon > {{ departure.slug === 'entrance_auto' ? 'mdi-truck-minus-outline' :'mdi-train'}}  mdi-48px</v-icon>
                        <v-card-subtitle> {{ departure.slug === 'entrance_auto' ? $t('list_of_current_car_allow_departure_inspections') : $t('list_of_current_inspections_allow_departure_by_railway')}}</v-card-subtitle>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <v-row v-if="can(['manager', 'guard'])">
            <v-col class="text-center mt-15" sm="12" cols="12">
                {{$t('please_choose_entry_or_exit')}}
            </v-col>
            <v-col class="text-center" sm="12" cols="12">
                <v-card slot="footer"  class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                >
                    <v-container class="fill-height py-10 justify-center"  @click="getSecurity('security.entry')">
                        <v-icon>mdi-truck-plus-outline mdi-48px</v-icon>
                        <v-card-subtitle>{{$t('entry')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card slot="footer"  class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                >
                    <v-container class="fill-height py-10 justify-center"  @click="getSecurity('security.exit')">
                        <v-icon>mdi-truck-minus-outline mdi-48px</v-icon>
                        <br />
                        <div><v-card-subtitle style="display: block">{{$t('exit')}}</v-card-subtitle></div>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <v-row v-if="can(['manager', 'driver'])">
            <v-col class="text-center mt-15" sm="12" cols="12">
                {{$t('click_to_move_containers_across_svx')}}
            </v-col>
            <v-col class="text-center" sm="12" cols="12">
                <v-card slot="footer"  class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                >
                    <v-container class="fill-height py-10 justify-center"  @click="getMoving()">
                        <v-icon>mdi-crane mdi-48px</v-icon>
                        <v-card-subtitle>{{$t('menu_moving')}}</v-card-subtitle>
                    </v-container>
                </v-card>

            </v-col>
        </v-row>


        <v-overlay :value="loading" z-index="10" color="primary">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </v-container>
</template>
<script>

import {mapGetters} from "vuex";

export default {
    name: "Dashboard",
    data() {
        return {
            options: {},
            loading: false,
            entrances: [],
            departures: [],

        }
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems', 'phoneMask']),
    },
    mounted() {
        this.options.itemsPerPage = this.perPage = this.itemsPerPage
        this.getEntrances();
    },

    methods: {
        getEntrance(item) {
            this.$router.push({
                name: item.slug,
                params: {
                    id: item.uuid
                }
            })
        },
        async getEntrances() {
            this.loading = true;
            let params = {};

            await this.$http
                .get("partner/container_type_receipt", {
                    params: params,
                })
                .then(res => {
                    this.entrances = res.body.data
                    this.departures = res.body.data
                })
                .catch(err => {
                    this.entrances = []
                    this.departures = []
                    this.$toastr.error(this.$t('failed_to_get_list_entrances'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        getSecurity(rout) {
            this.$router.push({
                name: rout,
            })
        },
        getMoving(rout) {
            this.$router.push({
                name: 'moving',
            })
        },
        getDepartures(item) {
            const regex = /entrance/i;
            let slug = item.slug.replace(regex, 'departure')
            this.$router.push({
                name: slug,
                params: {
                    id: item.uuid
                }
            })
        },
    }
}
</script>
